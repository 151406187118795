import router from "@/router";
import store from '@/store/index';
import {
    mini_url,
    front_url,
    committee_id
} from '@/config';
import QRCode from 'qrcodejs2'
import {
    Modal
} from 'ant-design-vue'
import { resolve } from "path";
// banner跳转方法 公用便于维护
const handleBanerToPage = function (data) {
    let {
        type,
        title,
        img,
        url,
        web_url
    } = data;
    if(web_url){
        window.open(web_url, '_blank');
    }else{
        switch (type) {
            // 跳转外联
            case '1':
                if (url) {
                    if (url.indexOf('wechat:/') != -1) {
                        url = url.split('wechat:')[1];
                        Modal.info({
                            title: '请使用手机微信扫一扫查看',
                            icon:'',
                            class:'dialog-qrcode',
                            okText:false,
                            closable:true,
                            content: (h) => {
                                return h(
                                    'div', {
                                        attrs: {
                                            id: 'qrcode', //设置属性id   可以在css 里面设置样式
                                            class:'flex x-center mt20',
                                        }
                                    })
                            }
                        })
                        this.$nextTick(() => {
                            var qrcode = new QRCode(document.getElementById('qrcode'), {
                                text: "https://www.medmeeting.org/m/?url="+encodeURIComponent(url),
                                width: 200,
                                height: 200,
                                colorDark: '#000000',
                                colorLight: '#ffffff',
                                correctLevel: QRCode.CorrectLevel.H
                            })
                        },100 )

                    } else {
                        window.open(url, '_blank')
                    }
                }
                break;
            // 会议跳转
            case '2':
                window.open(front_url+'cn/web/index/' + url, '_blank')
                break;
            // 直播跳转
            case '3':
                window.open(mini_url+'m/lives/details/' + url, '_blank')
                break;
            // 视频跳转
            case '4':
                // window.open(mini_url+'online/video/play/' + committee_id + '?vid=' + url, '_blank')
                router.push({
                    path: '/video-list-detail?id=' + url
                })
                break;
            // 专题跳转
            case '5':
                router.push({
                    path: '/special-detail?type_id=' + url
                })
                break;
            case '6':
                router.push({
                    path: '/special-detail?type=1&type_id=' + url
                })
                break;
            default:
        }
    }
    return;
}


//专家详情跳转
const expertJumpDetail = (id,listid,type,routeId) => {
    // console.log(id,type)
    let path
    if(type == 'special-ma'){ //综合专题
        path =  '/special-ma/user-detail/'+routeId+'?uid=' + id + '&listid='+listid
    }else{
        path =  '/person-detail?uid=' + id
    }
    router.push({
        path: path
    })
    // window.open(mini_url+'cn/person-detail/512?user_id=' + id, '_blank')
}
//会议快捷按钮跳转
const goRecordInfoPage = (type, item) =>{
    let url
    if (type === 'reg') {
        // 参会注册
        url = front_url + 'cn/reg/index/' + item.id
    } else if (type === 'Undertaking') {
        //防疫承诺书
        url = front_url + 'ticket/statement/' + item.id
    } else if (type === 'paper') {
        // 论文投稿
        url = front_url + 'cn/contribute/notes/' + item.id
    } else if (type == 'signIn'){
        //签到票
        url = front_url + 'cn/cn-ticket/ticket/' + item.id
    } else if (type == 'person'){
        // 个人中心
        url = front_url + 'cn/reg/info/' + item.id
    } else if (type == 'academic'){
        // 学术任务
        url = front_url + 'cn/program/user-duties/' + item.id
    } else if (type == 'invoice'){
        // 电子发票
        url = front_url + 'cn/order/index/' + item.id
    } else if (type == 'live'){
        // 观看直播
        if(item.live_id){
            url = mini_url + 'm/lives/details/' + item.live_id
        }else {
            url = mini_url + 'm/lives/lives-hall/' + item.id + '?lang=cn'
        }
    } else if (type == 'live_appoint'){
        //预约直播
        url = mini_url + 'm/lives/details/' + item.live_id
    } else if (type == 'video'){
        // 视频回放
        url = window.location.origin + '/#/special-detail?type=1&type_id=' + item.id
    }
    window.open(url, '_blank')
}
// 会议详情跳转
const meetJumpDetail = (href, id) => {
    let url = ''
    if (href) {
        url = href
    } else {
        url = front_url+'cn/web/index/' + id
    }
    window.open(url, '_blank')
}
// 直播详情跳转
const liveJumpDetail = (id) => {
    window.open(mini_url+'m/lives/details/' + id, '_blank')
}
// 视频详情跳转
const videoJumpDetail = (id,listId,type,routeId) => {
    // window.open(mini_url+'online/video/play/' + committee_id + '?vid=' + id, '_blank')
    // type == 'ma' 综合专题相关页面
    let path
    if(type == 'ma'){
        path = '/special-ma/video-detail/'+routeId+'?id=' + id + '&listId='+listId
    }else{
        path = '/video-list-detail?id=' + id
    }
    router.push({
        path: path
    })
}
// 会议论文文章详情壁报查看
const articlePicJumpDetail = (href) => {
    if (href) {
        window.open(href, '_blank')
    }
}
// 视频详情 会员权限4 参会注册观看权限6
const videoAuthJump = (type,id) => {
    let href = ''
    if(type == 4){
        href = mini_url + 'wPersonal/members/member-type/' + committee_id
    }else if(type == 6){
        href = front_url + 'cn/user/perfect-info/' + id
    }else{
        // 此时传过来的id为code_url
        href = id
    }
    window.open(href, '_blank')
}
// 登录
const login = ()=>{
    return new Promise((resolve,reject)=>{
        if(!(store.getters.userInfo||{}).user_id){
            Modal.info({
                title: '温馨提示',
                content:'您尚未登录，是否立即登录',
                okText:'立即登录',
                onOk() {
                    window.location.href = "https://www.sciconf.cn/unified/login?return_url="+encodeURIComponent(window.location.href);
                },
            })
        }else{
            resolve()
        }
    })
    
}

export {
    handleBanerToPage,
    expertJumpDetail,
    meetJumpDetail,
    liveJumpDetail,
    videoJumpDetail,
    articlePicJumpDetail,
    videoAuthJump,
    goRecordInfoPage,
    login
};